<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <b-card title="B2C 🚀">
      <b-card-text>Statistique de livraison</b-card-text>
    </b-card>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-row>
      <b-col
        lg="3"
        sm="6"
        @click="show(0)"
      >
        <statistic-card-with-area-chart
          v-if="result[0] != undefined"
          class="font-weight-bold"
          style="background-color: #CEF6FB; color: #007E90;"
          icon="AlertTriangleIcon"
          :statistic="`Colis en attente : ${result[0].number}`"
          :statistic-title="`Prix
          colis
          en
          attente :
          ${result[0].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(1)"
      >
        <statistic-card-with-area-chart
          v-if="result[1] != undefined"
          color="warning"
          class="font-weight-bold"
          icon="FileTextIcon"
          style="background-color: #FFE0F4; color: #F102BC;"
          :statistic="`Colis en pickup : ${result[1].number}`"
          :statistic-title="`Prix
          colis
          en
          pickup :
          ${result[1].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(2)"
      >
        <statistic-card-with-area-chart
          v-if="result[2] != undefined"
          icon="DownloadIcon"
          color="warning"
          class="font-weight-bold"
          style="background-color:#fff2df;"
          :statistic="`Colis en depot : ${result[2].number}`"
          :statistic-title="`Prix
          colis
          en
          depot :
          ${result[2].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(3)"
      >
        <statistic-card-with-area-chart
          v-if="result[3] != undefined"
          icon="TruckIcon"
          style="background-color:#9bf4d5;"
          class="font-weight-bold"
          color="success"
          :statistic="`Colis en cours : ${result[3].number}`"
          :statistic-title="`Prix
          colis
          en
          cour :
          ${result[3].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
    </b-row>
    <b-row>

      <b-col
        lg="3"
        sm="6"
        @click="show(4)"
      >
        <statistic-card-with-area-chart
          v-if="result[4] != undefined"
          icon="CheckSquareIcon"
          style="background-color:#ddeedd"
          color="success"
          class="font-weight-bold"
          :statistic="`Colis livré : ${result[4].number}`"
          :statistic-title="`Prix
          colis
          livré :
          ${result[4].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(6)"
      >
        <statistic-card-with-area-chart
          v-if="result[6] != undefined"
          icon="DownloadIcon"
          style="background-color:#e0ffcd;;"
          color="success"
          class="font-weight-bold"
          :statistic="`Colis livré payé : ${result[6].number}`"
          :statistic-title="`Prix
          colis
          livré payé :
          ${result[6].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(5)"
      >
        <statistic-card-with-area-chart
          v-if="result[5] != undefined "
          icon="TruckIcon"
          color="danger"
          class="font-weight-bold"
          style="background-color:#ffebeb;"
          :statistic="`Colis retour au depot : ${result[5].number}`"
          :statistic-title="`Prix
          colis
          retour au depot :
          ${result[5].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(7)"
      >
        <statistic-card-with-area-chart
          v-if="result[7] != undefined "
          icon="TruckIcon"
          color="danger"
          class="font-weight-bold"
          style="background-color:#f8b7ba;"
          :statistic="`Colis retour au fournisseur : ${result[7].number}`"
          :statistic-title="`Prix
          colis
          retour au fournisseur :
          ${result[7].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(9)"
      >
        <statistic-card-with-area-chart
          v-if="result[9] != undefined "
          icon="TruckIcon"
          color="danger"
          class="font-weight-bold"
          style="background-color:#ffaaa5;"
          :statistic="`Colis retour au fournisseur payé: ${result[9].number}`"
          :statistic-title="`Prix
          colis
          retour au fournisseur payé:
          ${result[9].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(8)"
      >
        <statistic-card-with-area-chart
          v-if="result[8] != undefined"
          icon="TrendingUpIcon"
          style="background-color:#e7eaf6;"
          color="info"
          class="font-weight-bold"
          :statistic="`Colis en transfert : ${result[8].number}`"
          :statistic-title="`Prix
          colis
          en
          transfert :
          ${result[8].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(12)"
      >
        <statistic-card-with-area-chart
          v-if="result[12] != undefined"
          icon="TrendingUpIcon"
          style="background-color:#FDF7E9;"
          color="info"
          class="font-weight-bold"
          :statistic="`Colis annulé par admin: ${result[12].number}`"
          :statistic-title="`Prix
          colis annulé par admin:
          ${result[12].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show(13)"
      >
        <statistic-card-with-area-chart
          v-if="result[13] != undefined"
          icon="TrendingUpIcon"
          style="background-color:#D4EDE4;"
          color="info"
          class="font-weight-bold"
          :statistic="`Colis perdu: ${result[13].number}`"
          :statistic-title="`Prix
          colis perdu:
          ${result[13].total_price_ttc} TND`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
    </b-row>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Recherche</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BCard, BCardText,
  BFormInput,
  BSpinner, BCol, BRow,

} from 'bootstrap-vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  name: 'Dashboard',
  components: {
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BSpinner,
    StatisticCardWithAreaChart,
    BCol,
    BRow,
  },
  data() {
    return {
      allStatus: [0, 1, 2, 3, 4, 5, 6, 7],
      result: [],
      isLoading: false,
      pageLength: 10,
      dir: false,
      subscribersGained: {
        series: [
          {
            name: '',
            data: [28, 40, 36, 52, 38, 60, 55],
          },
        ],
        analyticsData: {
          subscribers: 92600,
        },
      },
      columns: [
        {
          label: 'Livreur',
          field: 'user',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Livreur',
          },
        },
        {
          label: 'NB colis livré',
          field: 'nb_delivered',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher nb',
          },
        },
        {
          label: 'NB colis retour',
          field: 'nb_return',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher nb',
          },
        },
        {
          label: 'Total',
          field: 'total',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher total',
          },
        },

        {
          label: 'date | heure',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },

      ],
      user: {},
      transactions: [],
      searchTerm: '',
    }
  },
  created() {
    this.getOrders()
    this.user = storeAuth.state.user
  },
  methods: {
    show(level) {
      this.$router.push({ name: 'order-list', params: { level } })
    },
    async getOrders() {
      this.isLoading = true
      let supplier = null
      if (storeAuth.state.user.role === 'supplier') {
        supplier = storeAuth.state.user.id
      }
      const response = await axios.post('/api/orders/dashboard/',
        { supplier, repository: storeAuth.state.user.repository })
      this.result = response.data
      this.isLoading = false
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
